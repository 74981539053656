
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as _91_46_46_46slug_93y2heaR393NMeta } from "/var/www/projects/hillceramic.se/releases/20250408053629Z/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93CHzyBT86GKMeta } from "/var/www/projects/hillceramic.se/releases/20250408053629Z/pages/blogg/[...slug].vue?macro=true";
import { default as indexbwwM6AuDM8Meta } from "/var/www/projects/hillceramic.se/releases/20250408053629Z/pages/blogg/index.vue?macro=true";
import { default as indexRDpGUghWtBMeta } from "/var/www/projects/hillceramic.se/releases/20250408053629Z/pages/checkout/index.vue?macro=true";
import { default as paymentwQgj1pdwGnMeta } from "/var/www/projects/hillceramic.se/releases/20250408053629Z/pages/checkout/payment.vue?macro=true";
import { default as index_46client6kQAw4RfE0Meta } from "/var/www/projects/hillceramic.se/releases/20250408053629Z/pages/checkout/thank-you/index.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/var/www/projects/hillceramic.se/releases/20250408053629Z/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as indexUXrDkXEyHpMeta } from "/var/www/projects/hillceramic.se/releases/20250408053629Z/pages/collections/index.vue?macro=true";
import { default as _91slug_93nosmQJdaS5Meta } from "/var/www/projects/hillceramic.se/releases/20250408053629Z/pages/faq/[slug].vue?macro=true";
import { default as index9MZG0DAoCtMeta } from "/var/www/projects/hillceramic.se/releases/20250408053629Z/pages/hillceramic-rabattkoder/index.vue?macro=true";
import { default as indexgbAgMxWqgmMeta } from "/var/www/projects/hillceramic.se/releases/20250408053629Z/pages/index.vue?macro=true";
import { default as index5JEiish8VTMeta } from "/var/www/projects/hillceramic.se/releases/20250408053629Z/pages/login/index.vue?macro=true";
import { default as _91id_93vPKAk4OsNdMeta } from "/var/www/projects/hillceramic.se/releases/20250408053629Z/pages/qr-pr/[id].vue?macro=true";
import { default as _91_46_46_46slug_93ugiISK5mGPMeta } from "/var/www/projects/hillceramic.se/releases/20250408053629Z/pages/shop/[...slug].vue?macro=true";
import { default as indexiUEl8HWxmzMeta } from "/var/www/projects/hillceramic.se/releases/20250408053629Z/pages/track/index.vue?macro=true";
export default [
  {
    name: "slug___sv",
    path: "/:slug(.*)*",
    component: () => import("/var/www/projects/hillceramic.se/releases/20250408053629Z/pages/[...slug].vue")
  },
  {
    name: "blogg-slug___sv",
    path: "/blogg/:slug(.*)*",
    component: () => import("/var/www/projects/hillceramic.se/releases/20250408053629Z/pages/blogg/[...slug].vue")
  },
  {
    name: "blogg___sv",
    path: "/blogg",
    component: () => import("/var/www/projects/hillceramic.se/releases/20250408053629Z/pages/blogg/index.vue")
  },
  {
    name: "checkout___sv",
    path: "/checkout",
    meta: indexRDpGUghWtBMeta || {},
    component: () => import("/var/www/projects/hillceramic.se/releases/20250408053629Z/pages/checkout/index.vue")
  },
  {
    name: "checkout-payment___sv",
    path: "/checkout/payment",
    meta: paymentwQgj1pdwGnMeta || {},
    component: () => import("/var/www/projects/hillceramic.se/releases/20250408053629Z/pages/checkout/payment.vue")
  },
  {
    name: "checkout-thank-you___sv",
    path: "/checkout/thank-you",
    meta: index_46client6kQAw4RfE0Meta || {},
    component: () => createClientPage(() => import("/var/www/projects/hillceramic.se/releases/20250408053629Z/pages/checkout/thank-you/index.client.vue"))
  },
  {
    name: "collections___sv",
    path: "/kollektioner",
    component: () => import("/var/www/projects/hillceramic.se/releases/20250408053629Z/pages/collections/index.vue")
  },
  {
    name: "faq-slug___sv",
    path: "/faq/:slug()",
    component: () => import("/var/www/projects/hillceramic.se/releases/20250408053629Z/pages/faq/[slug].vue")
  },
  {
    name: "hillceramic-rabattkoder___sv",
    path: "/hillceramic-rabattkoder",
    component: () => import("/var/www/projects/hillceramic.se/releases/20250408053629Z/pages/hillceramic-rabattkoder/index.vue")
  },
  {
    name: "index___sv",
    path: "/",
    component: () => import("/var/www/projects/hillceramic.se/releases/20250408053629Z/pages/index.vue")
  },
  {
    name: "login___sv",
    path: "/login",
    component: () => import("/var/www/projects/hillceramic.se/releases/20250408053629Z/pages/login/index.vue")
  },
  {
    name: "qr-pr-id___sv",
    path: "/qr-pr/:id()",
    component: () => import("/var/www/projects/hillceramic.se/releases/20250408053629Z/pages/qr-pr/[id].vue")
  },
  {
    name: "shop-slug___sv",
    path: "/shop/:slug(.*)*",
    component: () => import("/var/www/projects/hillceramic.se/releases/20250408053629Z/pages/shop/[...slug].vue")
  },
  {
    name: "track___sv",
    path: "/track",
    meta: indexiUEl8HWxmzMeta || {},
    component: () => import("/var/www/projects/hillceramic.se/releases/20250408053629Z/pages/track/index.vue")
  }
]